<template>
    <div class="main-container">
      <h5 class="pageTitle">발급기관 수정/삭제</h5>
  
      <div class="form-container">
        <!-- 기관명 -->
        <div class="form-group">
          <label for="organization">기관명</label>
          <input v-model="form.organization" type="text" class="input-box" @input="validateForm" />
          <p class="error-message" v-if="errors.organization">{{ errors.organization }}</p>
        </div>
  
        <!-- 주소 -->
        <div class="form-group">
          <label for="address">주소</label>
          <input v-model="form.address" type="text" class="input-box" @input="validateForm" />
          <p class="error-message" v-if="errors.address">{{ errors.address }}</p>
        </div>
  
        <!-- 담당자 -->
        <div class="form-group">
          <label for="officer">담당자</label>
          <input v-model="form.officer" type="text" class="input-box" @input="validateForm" />
          <p class="error-message" v-if="errors.officer">{{ errors.officer }}</p>
        </div>
  
        <!-- 연락처 -->
        <div class="form-group">
          <label for="phone">연락처</label>
          <input v-model="form.phone" type="text" class="input-box" @input="validateForm" />
          <p class="error-message" v-if="errors.phone">{{ errors.phone }}</p>
        </div>
  
        <div class="button-container">
          <button class="button-list" @click="goToList">목록</button>
          <button class="button-update" @click="updateOrganization" :disabled="hasErrors">수정</button>
          <button class="button-delete" @click="deleteOrganization">삭제</button>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";
  
  export default {
    name: "CertOrganUpdateDelete",
    data() {
      return {
        form: {
          uuid: "",
          organization: "",
          address: "",
          officer: "",
          phone: ""
        },
        errors:{},
      };
    },
    created() {

        const parsedData = JSON.parse(this.$route.query.data);
        this.form = { ...parsedData };  // form에 바로 바인딩
      
    },
    computed: {
        hasErrors() {
            return Object.keys(this.errors).length > 0;
        }
    },
    methods: {
        validateForm() {
            this.errors = {};

            if (!this.form.organization.trim()) {
            this.errors.organization = '기관명을 입력하세요.';
            }
            if (!this.form.address.trim()) {
            this.errors.address = '주소를 입력하세요.';
            }
            if (!this.form.officer.trim()) {
            this.errors.officer = '담당자명을 입력하세요.';
            }
            if (!this.form.phone.trim()) {
            this.errors.phone = '연락처를 입력하세요.';
            }
        },
  // ... 기존 update, delete 함수 등

      async updateOrganization() {
        try {
          const tokenData = JSON.parse(sessionStorage.getItem("token"));
          const token = tokenData?.access_token || "";
  
          await axios.put(`/master/issuing-organization/${this.form.uuid}`, this.form, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
  
          Swal.fire("성공", "수정이 완료되었습니다.", "success");
          this.$router.push({ name: "master-certOrganRead" });
        } catch (error) {
          console.error("수정 실패:", error);
          Swal.fire("오류", "수정 중 오류가 발생했습니다.", "error");
        }
      },
  
      async deleteOrganization() {
        const result = await Swal.fire({
          title: "삭제 확인",
          text: "정말 삭제하시겠습니까?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "삭제",
          cancelButtonText: "취소",
        });
  
        if (result.isConfirmed) {
          try {
            const tokenData = JSON.parse(sessionStorage.getItem("token"));
            const token = tokenData?.access_token || "";
  
            await axios.delete(`/master/issuing-organization/${this.form.uuid}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
  
            Swal.fire("삭제 완료", "발급기관 정보가 삭제되었습니다.", "success");
            this.$router.push({ name: "master-certOrganRead" });
          } catch (error) {
            console.error("삭제 실패:", error);
            Swal.fire("오류", "삭제 중 오류가 발생했습니다.", "error");
          }
        }
      },
  
      goToList() {
        this.$router.push({ name: "master-certOrganRead" });
      },
    },
  };
  </script>
  
<style scoped>
/* 동일한 스타일 유지 */
.main-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageTitle {
  font-weight: bold;
  margin-bottom: 40px;
  text-align: left;
  width: 100%;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 70%;
  max-width: 800px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.input-box,
.select-box {
  /* flex: 1; */
  width: 600px;
  padding: 8px;
  margin-right: 40px;
  border: 1px solid rgba(130, 128, 128, 0.26);
  border-radius: 3px;
  
}

.input-select {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}

.button-list {
  background-color: #ff9800;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.button-update {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.button-delete {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
</style>
    

